import React, { useCallback } from 'react'
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleIcon } from '../../Theme/icons'
import { Button } from '../Button';
import { GOOGLE_LOGIN_MUTATION } from '../../Actions/Auth/GoogleAuth';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setLoggedInUser } from '../../Redux/reducer';
import { useShowToast } from '../../Utils/toast'; 

const GoogleLogin = () => {
  const showToast = useShowToast();
  const dispatch = useDispatch();
  const [googleMutation] = useMutation(GOOGLE_LOGIN_MUTATION);

  const onLoginStart = useCallback(() => {
    console.log('login start');
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }, []);

  const handleGoogleAuth = async (googleUserData) => {
    const { given_name, family_name, email, picture } = googleUserData;
    console.log(googleUserData, "googleUserData");

    try {
      const { data } = await googleMutation({ variables: { email: email || null, firstName: given_name, lastName: family_name } });
      const { googleAuth = {} } = data || { googleAuth: {} }
      const { profile = {}, token = "" } = googleAuth || { profile: {}, token: "" };
      dispatch(setLoggedInUser({ token, profile: { ...profile, profileImage: picture },socialMediaLogin:true  }));
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify({ profile: { ...profile, profileImage: picture },socialMediaLogin:true  }));
      // localStorage.removeItem('cart')
      // dispatch(updateCartItems([]))
      showToast({ message: "User login successful", variant: 'success' })
    } catch (error) {
      showToast({ message: error?.message, variant: 'error' })
    }
  };

  return (
    <LoginSocialGoogle
      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onResolve={({ data }) => handleGoogleAuth(data)}
      scope="openid profile email"
      onReject={(err) => console.log(err)}
      onLoginStart={onLoginStart}
    >
      <Button
        loader={false}
        loaderText=""
        label="Continue with Google"
        iconClassName=""
        loaderClassName=""
        icon={<GoogleIcon />}
        variant="social-button"
        className="w-full"
      />
    </LoginSocialGoogle>
  )
}

export default GoogleLogin
