import React, { useCallback } from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookIcon } from '../../Theme/icons';
import { Button } from '../Button';
import { FACEBOOK_MUTATION } from '../../Actions/Auth/FacebookAuth';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setLoggedInUser } from '../../Redux/reducer';
import { useShowToast } from '../../Utils/toast'; 
import { useNavigate } from 'react-router-dom';

const FacebookLogin = () => {
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const navigate = useNavigate()
  const [facebookAuthMutation] = useMutation(FACEBOOK_MUTATION);

  const handleFacebookResponse = async (response) => {
    const { picture = {} } = response || { picture: {} }
    const { data: fbResponse } = picture || { data: {} }
    const { url = '' } = fbResponse || { url: "" }
    console.log(url, "url");

    try {
      const { id, first_name, last_name } = response;
      const { data } = await facebookAuthMutation({
        variables: {
          facebookId: id,
          firstName: first_name,
          lastName: last_name,
        },
      });
      const { facebookAuth = {} } = data || { facebookAuth: {} }
      const { token = "" } = facebookAuth || { token: "" };

      dispatch(setLoggedInUser({ token, profile: { firstName: first_name, lastName: last_name, profileImage: url },socialMediaLogin:true }));
      localStorage.setItem('token', token);
      // localStorage.removeItem('cart');
      // dispatch(updateCartItems([]))
      localStorage.setItem('user', JSON.stringify({ profile: { firstName: first_name, lastName: last_name, profileImage: url },socialMediaLogin:true  }));
      showToast({ message: "User login successful", variant: 'success' })
      navigate('/home') 
    } catch (error) {
      showToast({ message: error?.message, variant: 'error' })
    }
  };

  const onLoginStart = useCallback(() => {
    console.log('FB ,login start');
  }, []);
  return (
    <LoginSocialFacebook
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fieldsProfile={
        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
      }
      onReject={err => {
        console.log(err);
      }}
      onLoginStart={onLoginStart}
      onResolve={({ data }) => handleFacebookResponse(data)}

    >
      <Button
        label="Continue with Facebook"
        icon={<FacebookIcon />}
        variant="social-button-primary"
        className="w-full"
      />
    </LoginSocialFacebook>
  );
};

export default FacebookLogin;
